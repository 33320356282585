import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import Info from '../views/InfoView.vue'
import { auth } from '../firebase'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/info',
    name: 'Info',
    component: Info

  },
  {
    path: '/students',
    name: 'Students',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StudentsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usermanagement',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UsermanagementView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/manualsync',
    name: 'Manuele synchronisatie',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualSync.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/joboverview',
    name: 'Job overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/JobOverview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/erroroverview',
    name: 'Error overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ErrorOverview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cronjoberroroverview',
    name: 'Cronjob error overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CronjobErrorOverview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workspaceglobals',
    name: 'Workspace situatie',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkspaceGlobals.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let userisloggedin = false
// navigation guard to check for logged in users
auth.onAuthStateChanged(function (user) {
  console.log(user)
  if (user) {
    userisloggedin = true
    console.log('Whoop! user is logged in!')
    store.dispatch('setLoggedIn', user)
  } else {
    console.log('No User: redicecting to login page!')
    userisloggedin = false
  }
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (requiresAuth && !userisloggedin) {
      next('/login')
    } else {
      next()
    }
  })
})

/*

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  var userisloggedin = false

   if (requiresAuth && !userisloggedin) {
    next('/login')
  } else {
    next()
  }

}) */

export default router
