<template>
<div class="container">
  <div class="home w-100 d-flex justify-content-center">
    <img alt="logo" class="img-fluid" src="../assets/logo_large.png" />

  </div>

         <div v-if="user.loggedIn" class="alert alert-success" role="alert">
        You are logged in!
        </div>
        <div v-if="!user.loggedIn" class="alert alert-success" role="alert">
        Login to get started!
        </div>
</div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },
  name: 'HomeView'

}
</script>
