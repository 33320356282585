import Vue from 'vue'
import Vuex from 'vuex'
// import Vuex, { store } from 'vuex'

import * as fb from './firebase'
import router from './router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    students: [],
    unsubscribers: [],
    workingSchool: [],

    saveWorkspaceGlobalsResponse: {},
    removeNonInformatAndNonManualItemsResponse: {},
    updateInformatDataResponse: {},
    updateGoogleDataResponse: {},
    getAndWriteDataToSpreadsheetResponse: {},

    // https://codeseven.github.io/toastr/demo.html
    toastrOptions: {
      closeButton: true,
      debug: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      onclick: null,
      showDuration: '300',
      hideDuration: '2000',
      timeOut: '8000',
      extendedTimeOut: '2000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      maxOpened: 1
    }
  },

  getters: {
    user (state) {
      return state.user
    },
    students (state) {
      return state.students
    },
    unsubscribers (state) {
      return state.unsubscribers
    },
    toastrOptions (state) {
      return state.toastrOptions
    },
    workingSchool (state) {
      return state.workingSchool
    },
    saveWorkspaceGlobalsResponse (state) {
      return state.saveWorkspaceGlobalsResponse
    },
    removeNonInformatAndNonManualItemsResponse (state) {
      return state.removeNonInformatAndNonManualItemsResponse
    },
    updateInformatDataResponse (state) {
      return state.updateInformatDataResponse
    },
    updateGoogleDataResponse (state) {
      return state.updateGoogleDataResponse
    },
    getAndWriteDataToSpreadsheetResponse (state) {
      return state.getAndWriteDataToSpreadsheetResponse
    }
  },

  mutations: {
    SET_LOGGED_IN (state, value) {
      console.log('new value is %s', value)
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    SET_STUDENTS (state, val) {
      console.log('STUDENTS ARE BEING SET...')
      state.students = val
    },
    SET_USER_CLAIMS (state, data) {
      /* console.log('user data: ')
      console.log(data) */
      console.log('ADDING USER CLAIMS...', data)
      state.user.claims = data
    },
    SET_GOOGLE_ACCESS_TOKEN (state, data) {
      /* console.log('user data: ')
      console.log(data) */
      console.log('ADDING USER GOOGLE ACCESS TOKEN...', data)
      state.user.googleAccessToken = data
    },
    ADD_UNSUBSCRIBER (state, newUnsubscriber) {
      if (state.unsubscribers.indexOf(newUnsubscriber) <= -1) {
        state.unsubscribers.push(newUnsubscriber)
      } else {
        console.log('Unsubscriber skipped... already existed.')
      }
    },
    UNSUBSCRIBE_ALL (state) {
      state.unsubscribers.forEach(unsubscriber => unsubscriber())
      console.log('All unsubscribed...')
      state.unsubscribers = []
    },
    SET_WORKINGSCHOOL (state, school) {
      console.log('WORKING SCHOOL IS BEING SET AS: ' + school)
      state.workingSchool = school
    },
    SET_SAVEWORKSPACEGLOBALSRESPONSE (state, response) {
      console.log('SAVEWORKSPACEGLOBALSRESPONSE SCHOOL IS BEING SET AS: ' + response)
      state.saveWorkspaceGlobalsResponse = response
    },
    SET_REMOVENONINFORMATANDNONMANUALITEMSRESPONSE (state, response) {
      console.log('REMOVENONINFORMATANDNONMANUALITEMSRESPONSE SCHOOL IS BEING SET AS: ' + response)
      state.removeNonInformatAndNonManualItemsResponse = response
    },
    SET_UPDATEINFORMATDATARESPONSE (state, response) {
      console.log('UPDATEINFORMATDATARESPONSE SCHOOL IS BEING SET AS: ' + response)
      state.updateInformatDataResponse = response
    },
    SET_UPDATEGOOGLEDATARESPONSE (state, response) {
      console.log('UPDATEGOOGLEDATARESPONSE SCHOOL IS BEING SET AS: ' + response)
      state.updateGoogleDataResponse = response
    },
    SET_GETANDWRITEDATATOSPREADSHEETRESONSE (state, response) {
      console.log('GETANDWRITEDATATOSPREADSHEETRESONSE SCHOOL IS BEING SET AS: ' + response)
      state.getAndWriteDataToSpreadsheetResponse = response
    }
  },

  actions: {
    async login ({ commit, dispatch }, routeObj) {
      console.log("Let's login!")
      // sign user in
      const provider = new fb.fireb.auth.GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/spreadsheets')
      fb.auth.signInWithPopup(provider).then(async (result) => {
        const credential = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = credential.accessToken
        console.log('Google Token found! ', token)
        commit('SET_GOOGLE_ACCESS_TOKEN', token)
        // The signed-in user info.
        const user = result.user

        dispatch('setLoggedIn', user)
        const tokenresult = await fb.auth.currentUser.getIdTokenResult()
        const customclaims = tokenresult.claims

        console.log('customclaims', customclaims)
        commit('SET_USER_CLAIMS', customclaims)
        if (routeObj) {
          router.push(routeObj.route).catch(() => {})
        } else {
          router.push('/info').catch(() => {})
        }
      }).catch((error) => {
        alert(error)
        console.log(error)
        commit('SET_USER', null)
      })

      /*
      const { user } = await fb.auth.signInWithPopup(provider)
      // fetch user profile and set in state
      console.log('USER', user)
      if (user) {
        var tokenresult = await fb.auth.currentUser.getIdTokenResult()
        console.log('TOKENRESULT', tokenresult)
        var customclaims = tokenresult.claims
        // https://gist.github.com/jamestalmage/2d8d1d5c42157caf349e
        customclaims.tokenObject = tokenresult.credential.accessToken

        console.log('CUSTOMCLAIMS', JSON.stringify(customclaims))

        commit('SET_USER_CLAIMS', customclaims)
        await dispatch('setLoggedIn', user)
        */
      /*  router.push('/info')
      } else {
        commit('SET_USER', null)
      } */
    },
    async setLoggedIn ({ commit, dispatch }, user) {
      commit('SET_USER', user)
      commit('SET_LOGGED_IN', true) // user !== null)
      try { await fb.db.collection('usersadmin').doc(await user.uid).update({ photoURL: user.photoURL }, { merge: true }) } catch (e) { console.log('unable to update image...'); console.log(e) }

      const tokenresult = await fb.auth.currentUser.getIdTokenResult()
      const customclaims = tokenresult.claims

      commit('SET_USER_CLAIMS', customclaims)
    },
    async logout ({ commit }) {
      console.log('Logging you out, dude!')
      // unsubscribe()
      commit('SET_STUDENTS', [])
      await commit('UNSUBSCRIBE_ALL')
      console.log('unsubscribed!')
      await fb.auth.signOut()
      commit('SET_USER', null)
      commit('SET_LOGGED_IN', false) // user !== null)
      commit('SET_USER_CLAIMS', {})
    },
    async setStudents ({ commit, dispatch }, students) {
      console.log('setting the students')
      commit('SET_STUDENTS', students)
    },
    async clearCurrentStudents ({ commit }) {
      commit('SET_STUDENTS', [])
      await commit('UNSUBSCRIBE_ALL')
      console.log('unsubscribed!')
    },
    async setSavedSchool ({ commit, dispatch }, school) {
      console.log('Working school is being saved...')
      await commit('UNSUBSCRIBE_ALL')
      console.log('unsubscribed!')
      commit('SET_WORKINGSCHOOL', school)
    },

    async setSaveWorkspaceGlobalsResponse ({ commit, dispatch }, response) {
      console.log('saveWorkspaceGlobalsResponse school is being saved...')
      commit('SET_SAVEWORKSPACEGLOBALSRESPONSE', response)
    },

    async setRemoveNonInformatAndNonManualItemsResponse ({ commit, dispatch }, response) {
      console.log('removeNonInformatAndNonManualItemsResponse school is being saved...')
      commit('SET_REMOVENONINFORMATANDNONMANUALITEMSRESPONSE', response)
    },

    async setUpdateInformatDataResponse ({ commit, dispatch }, response) {
      console.log('updateInformatDataResponse school is being saved...')
      commit('SET_UPDATEINFORMATDATARESPONSE', response)
    },

    async setUpdateGoogleDataResponse ({ commit, dispatch }, response) {
      console.log('updateGoogleDataResponse school is being saved...')
      commit('SET_UPDATEGOOGLEDATARESPONSE', response)
    },

    async setGetAndWriteDataToSpreadsheetResponse ({ commit, dispatch }, response) {
      console.log('getAndWriteDataToSpreadsheetResponse school is being saved...')
      commit('SET_GETANDWRITEDATATOSPREADSHEETRESONSE', response)
    },
    async addUnsubscriber ({ commit, dispatch }, newUnsubscriber) {
      console.log('Adding a new unsubscriber')
      commit('ADD_UNSUBSCRIBER', newUnsubscriber)
    }
  }
})

export default store
