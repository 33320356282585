<template>
  <div class="container">
  <div class="home w-100 d-flex justify-content-center">
    <img alt="logo" class="img-fluid" src="../assets/logo_large.png" />

  </div>
    <div class="card text-center">
      <div v-if="user.loggedIn" class="alert alert-success" role="alert">
        Je bent goed ingelogd. Bekijk via onderstaande knop je
        accountinstellingen.
      </div>
      <div class="mb-2">
        <button class="btn btn-outline-primary" v-on:click="toggleAccount()">
          <span class="material-icons">account_circle</span>Toon accountdetails<span v-if="toonAccountgegevens" class="material-icons">expand_less</span><span v-else class="material-icons">expand_more</span></button>
      </div>

      <div v-if="toonAccountgegevens">
        <ul class=" list-group w-75 center mb-3 mt-3">
          <li
            class="list-group-item list-group-item-info font-weight-bold"
            v-if="!user.claims || !user.claims.provisioned"
          >
            Je account moet nog goedgekeurd worden.
            <div class="m-3 shadow">
   <ul class="list-group ">
              <li class="list-group-item active">ACCOUNT GEPROVISIONEERD</li>
              <li class="list-group-item">
                PROVISIONED: {{ user.claims.provisioned }}
              </li>
            </ul>
            </div>

          </li>
          <li
            class="list-group-item list-group-item-info font-weight-bold"
            v-if="user.claims && user.claims.provisioned"
          >
            Je account werd al goedgekeurd.
          </li>

          <li class="list-group-item list-group-item-info font-weight-bold">
            Rechten krijgen op de juiste scholen.
            <ul v-if="user.claims && user.claims.schools" class="list-group">
              <li class="list-group-item active">OVERZICHT RECHTEN SCHOLEN</li>
              <li class="list-group-item">
                FEA: {{ user.claims.schools.FEA }}
              </li>
              <li class="list-group-item">
                FMA: {{ user.claims.schools.FMA }}
              </li>
              <li class="list-group-item">
                FML: {{ user.claims.schools.FML }}
              </li>
              <li class="list-group-item">
                FMT: {{ user.claims.schools.FMT }}
              </li>
              <li class="list-group-item">
                FMM: {{ user.claims.schools.FMM }}
              </li>
              <li class="list-group-item">
                FMP: {{ user.claims.schools.FMP }}
              </li>
              <li class="list-group-item">
                FPP: {{ user.claims.schools.FPP }}
              </li>
            </ul>
          </li>
          <li class="list-group-item list-group-item-info font-weight-bold"><button class="btn btn-info" @click="showUserClaims = !showUserClaims">Volledige userclaims weergeven</button><pre v-if="showUserClaims">{{user}}</pre></li>

        </ul>
      </div>

      <div class="">
         <div class="card-body">

    <div class="card-text">
      <ul class="list-group">
        <li class="list-group-item active"> <span class="material-icons">
flag
</span> Aan de slag</li>
        <li class="list-group-item ">Setup: <br>
        Bij Informat: Per school zorgen dat referentiedatum ofwel op "vandaag" staat ofwel op "03/09/2022" (dd/mm/yyyy)<br>
        Ook het schooljaar moet juist staan "yyyy-yy"<br>
        Bij Workspace: schooljaar aanpassen!<br>
        Bij het begin van een schooljaar, alle school-collections verwijderen en opnieuw laten syncen. Ook de google-collections verwijderen. (behalve google-orgs > orgs)
        </li>

      </ul>
    </div>
  </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.center {
  margin: 0 auto;
}
</style>
<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },
  data () {
    return {
      toonAccountgegevens: false,
      showUserClaims: false
    }
  },
  methods: {
    toggleAccount: function () {
      this.toonAccountgegevens = !this.toonAccountgegevens
    }
  }
}
</script>
