import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import firebaseSecret from './firebase.secret.json'

firebase.initializeApp(firebaseSecret)

// utils
const fireb = firebase
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.app().functions()
// collection references
const usersCollection = db.collection('usersadmin')
const jobCollection = db.collection('JOBS')
const errorCollection = db.collection('ERRORLOGS')
const cronJobErrorsCollection = db.collection('CRONJOBLOGS')

// export utils/refs
export {
  fireb,
  functions,
  db,
  auth,
  usersCollection,
  jobCollection,
  errorCollection,
  cronJobErrorsCollection
}
