<template>
  <div id="app" class="container mt-1">
    <div class="customnav d-flex flex-row flex-nowrap w-100 align-items-center">
      <ul class="nav nav-pills justify-content-end align-items-center">
        <li class="nav-item">
      <router-link class="nav-link" to="/">Home</router-link>
</li>
        <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user.loggedIn" to="/info"><span class="material-icons">
info
</span> Info</router-link>
        </li>
  <li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" to="/manualsync"><span class="material-icons">
cloud_sync
</span> Manuele synchronisatie</router-link>
</li>
  <li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" to="/joboverview"><span class="material-icons">
task
</span> Jobs</router-link>
</li>
<li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" to="/erroroverview"><span class="material-icons">
report
</span> Errors</router-link>
</li>
<li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" to="/cronjoberroroverview"><span class="material-icons">
terminal
</span> Cronjob Errors</router-link>
</li>
<li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" to="/workspaceglobals"><span class="material-icons">
ballot
</span> Workspace situatie</router-link>
</li>
   <li class="nav-item" v-if="user && user.claims">
      <router-link class="nav-link" active-class="active" v-if="user" to="/students"><span class="material-icons">
storage
</span> Students</router-link>
        </li>

   <li class="nav-item" v-if="user && user.claims && user.claims.superuser">
      <router-link class="nav-link" active-class="active" v-if="user" to="/usermanagement"><span class="material-icons">
        manage_accounts
</span> Users</router-link>
        </li>
  <li class="nav-item" v-if="!user.loggedIn">
      <router-link class="nav-link"  to="/login"><span class="material-icons">
        login
</span> Login</router-link>
</li>
  <li class="nav-item"  v-if="user.loggedIn">
      <router-link class="nav-link" to="/login"> <span class="material-icons">
        logout
</span> Logout</router-link>
</li>

   </ul>
    </div>
    <router-view/>

  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 // text-align: center;
  color: #2c3e50;
}
:disabled:hover{
  cursor: not-allowed
}

</style>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  }
}
</script>
